import ArrowRight from '../../../assets/arrow-right-large-link.inline.svg';
import BottomBorder from '../../../assets/hangout-bottom-border.inline.svg';
import G from '../../../assets/g.inline.svg';
import React from 'react';
import TopBorder from '../../../assets/hangout-top-border.inline.svg';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  max-width: 31rem;
  margin-bottom: 5rem;
  margin-top: 5rem;

  @media ${props => props.theme.breakpoints.xs} {
    margin-bottom: 5rem;
  }

  @media ${props => props.theme.breakpoints.md} {
    margin-bottom: 0;
  }
`;

const borderCss = `
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  float: left;
`;

const StyledTopBorder = styled(TopBorder)`
  ${borderCss};
  margin-top: -0.1rem;
`;

const StyledBottomBorder = styled(BottomBorder)`
  ${borderCss};
  margin-top: -0.1rem;
`;

const LinkBox = styled.div`
  padding: 1.5rem;
  border-left: 0.1rem solid ${props => props.theme.colors.meteor};
  border-right: 0.1rem solid ${props => props.theme.colors.meteor};
`;

const Time = styled.div`
  font-family: ${props => props.theme.fonts.serif};
  font-weight: normal;
  margin-bottom: 0.6rem;

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 1.6rem;
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 2rem;
  }

  span {
    font-family: ${props => props.theme.fonts.sansSerif};
  }
`;
const Hangout = styled.a`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.meteor};
  font-family: ${props => props.theme.fonts.sansSerif};
  font-weight: bold;

  @media ${props => props.theme.breakpoints.xs} {
    font-size: 2.4rem;
  }

  @media ${props => props.theme.breakpoints.sm} {
    font-size: 3rem;
  }
`;

const HangoutLink = () => {
  return (
    <Container>
      <StyledTopBorder />
      <LinkBox>
        <Time>
          Lets talk <span>9am-5pm</span> Poland time
        </Time>
        <Hangout rel="noopener noreferrer" target="_blank" href="https://hangouts.google.com/group/r6yu2FnuEC6FLzT5A">
          <G />
          ForestPlanting
          <ArrowRight />
        </Hangout>
      </LinkBox>
      <StyledBottomBorder />
    </Container>
  );
};

export default HangoutLink;
